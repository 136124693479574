<template>
  <div>
    <v-card flat>
      <v-card-title>
        <label class="primary--text px-5 font-weight-bold info-page-title ma-auto">{{ $t(`label.tnc`) }}</label>
      </v-card-title>
    </v-card>

    <v-card class="info-page-card">
      <v-expansion-panels accordion v-model="panel">
        <v-expansion-panel v-for="info in tncContent" :key="info.title">
          <v-expansion-panel-header class="font-weight-bold text-uppercase">{{ info.title }}</v-expansion-panel-header>
          <v-expansion-panel-content class="py-4 info-expension-panel-content">
            <div v-for="content in info.content" :key="content.title">
              <p class="text-capitalize font-weight-bold info-expension-panel-content-title mb-1" v-html="content.title"></p>
              <p v-html="content.desc"></p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
import { locale, uiHelper } from "@/util";
export default {
  name: 'aboutUs',
  data: () => ({
    panel: 0,
    tncContent: [
      {
        title: locale.getMessage(`info.info_tnc`),
        content: [
          {
            title: locale.getMessage(`info.info_tnc_a_title`),
            desc: locale.getMessage(`info.info_tnc_a_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_b_title`),
            desc: locale.getMessage(`info.info_tnc_b_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_c_title`),
            desc: locale.getMessage(`info.info_tnc_c_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_d_title`),
            desc: locale.getMessage(`info.info_tnc_d_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_e_title`),
            desc: locale.getMessage(`info.info_tnc_e_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_d_title`),
            desc: locale.getMessage(`info.info_tnc_d_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_e_title`),
            desc: locale.getMessage(`info.info_tnc_e_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_f_title`),
            desc: locale.getMessage(`info.info_tnc_f_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_g_title`),
            desc: locale.getMessage(`info.info_tnc_g_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_h_title`),
            desc: locale.getMessage(`info.info_tnc_h_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_i_title`),
            desc: locale.getMessage(`info.info_tnc_i_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_j_title`),
            desc: locale.getMessage(`info.info_tnc_j_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_k_title`),
            desc: locale.getMessage(`info.info_tnc_k_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_l_title`),
            desc: locale.getMessage(`info.info_tnc_l_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_m_title`),
            desc: locale.getMessage(`info.info_tnc_m_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_n_title`),
            desc: locale.getMessage(`info.info_tnc_n_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_o_title`),
            desc: locale.getMessage(`info.info_tnc_o_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_p_title`),
            desc: locale.getMessage(`info.info_tnc_p_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_q_title`),
            desc: locale.getMessage(`info.info_tnc_q_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_r_title`),
            desc: locale.getMessage(`info.info_tnc_r_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_s_title`),
            desc: locale.getMessage(`info.info_tnc_s_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_t_title`),
            desc: locale.getMessage(`info.info_tnc_t_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_u_title`),
            desc: locale.getMessage(`info.info_tnc_u_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_v_title`),
            desc: locale.getMessage(`info.info_tnc_v_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_w_title`),
            desc: locale.getMessage(`info.info_tnc_w_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_x_title`),
            desc: locale.getMessage(`info.info_tnc_x_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_y_title`),
            desc: locale.getMessage(`info.info_tnc_y_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_z_title`),
            desc: locale.getMessage(`info.info_tnc_z_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_ab_title`),
            desc: locale.getMessage(`info.info_tnc_ab_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_ac_title`),
            desc: locale.getMessage(`info.info_tnc_ac_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_ad_title`),
            desc: locale.getMessage(`info.info_tnc_ad_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_ae_title`),
            desc: locale.getMessage(`info.info_tnc_ae_desc`)
          },
          {
            title: locale.getMessage(`info.info_tnc_af_title`),
            desc: locale.getMessage(`info.info_tnc_af_desc`)
          },
        ]
      }
    ]
  }),
  mounted() {
    this.metaTag()
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href;
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      const Linksenin = `https://${domain}/en-IN${cleanPath}`;
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`;
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`;
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`;

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    }
  }
}
</script>
